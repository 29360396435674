"use strict";

import 'lazysizes';
import "/src/css/style.scss";
// import "/node_modules/modern-css-reset/";
import Swiper, { Navigation, Pagination, EffectFade, A11y, Lazy, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/lazy';
import 'swiper/css/effect-fade';
import 'swiper/css/a11y';

/* --------------------------------------------------------- Check JS enabled */

const removeNoJs = function() {
  document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, '') + ' js ';
}

/* -------------------------------------------------------------- Mobile menu */

const mobileMenu = function() {
  if (document.querySelector('.js-navigation__toggle')) {

    var menuToggle = document.querySelector('.js-navigation__toggle');
    var html = document.getElementsByTagName('html')[0];

    var toggleMenu = function(e) {
      e.preventDefault();
      html.classList.toggle('has-navigation-open');
    }

    var openMenu = function(e) {
      e.preventDefault();
      html.classList.add('has-navigation-open');
    }

    var closeMenu = function(e) {
      e.preventDefault();
      html.classList.remove('has-navigation-open');
    }

    menuToggle.addEventListener('click', toggleMenu);

    document.onkeydown = function(evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        html.classList.remove('has-navigation-open');
      }
    };
  }
}

// Logo only appears on scroll on homepage
function toggleLogo() {

  console.log('frontpage');
  if (document.querySelector('.frontpage')) {
    var masthead = document.querySelector('.masthead');
    var cover = document.querySelector('.c-cover');
    var coverHeight = cover.offsetHeight
    window.addEventListener('scroll', function() {
      var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
    	//console.log(scrollTop + ' ' + coverHeight);
      if ( scrollTop > coverHeight ) {
    	    masthead.classList.add("masthead--stuck");
    	  } else {
    	    masthead.classList.remove("masthead--stuck");
    	  }
  	});
  }
}

/* -------------------------------------------------------- Homepage carousel */

function coverCarousel() {
  if (document.querySelector('.js-cover-carousel')) {
    var numberOfSlides = document.querySelectorAll('.swiper-slide').length;
    var mySwiper = new Swiper ('.swiper', {
      modules: [Navigation, Pagination, Pagination, A11y, EffectFade, Lazy, Keyboard],
      initialSlide: Math.floor((Math.random() * numberOfSlides)),
      loop: true,
      preloadImages: false,
      // Enable lazy loading
      lazy: true,
      speed: 2000,
      // Fade effect
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },

      // Autoplay
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },

      // Pagination
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // Keyboard support
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },

      a11y: {
          prevSlideMessage: 'Previous image',
          nextSlideMessage: 'Next image',
          paginationBulletMessage: 'Go to image {{index}}'
      },

      // events
      on: {
        transitionStart: function() {
          var currentSlideIndex = this.activeIndex;
          var currentSlide = document.querySelectorAll('.swiper-slide')[currentSlideIndex];
          var currentSlideContrast = currentSlide.getAttribute('data-contrast');
          this.el.setAttribute('data-contrast', currentSlideContrast);

          this.params.speed = 400;
        },
        transitionEnd: function () {
          this.params.speed = 2000;
        },
        touchStart: function () {
          this.params.speed = 400;
        },
      }
    })
  }
}

/* ------------------------------------------------------------ Book carousel */

function bookCarousel() {
  if (document.querySelector('.js-carousel')) {
    var mySwiper = new Swiper ('.swiper', {
      modules: [Navigation, Pagination, Pagination, A11y, EffectFade, Lazy, Keyboard],
      // Optional parameters
      direction: 'horizontal',
      loop: true,

      // Fade effect
      fadeEffect: {
        crossFade: true
      },

      // If we need pagination
       pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      // Keyboard support
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
    })
  }
}

/* -------------------------------------------------------- Mailing list ajax */

// function mailingListAjaxSubscription (){
//   if (document.querySelector('.mailing-list-subscribtion')) {
//
//         // $('#subscription').submit(function(e) {
//         //     e.preventDefault();
//         //     $.getJSON(
//         //         this.action + "?callback=?",
//         //         $(this).serialize(),
//         //         function(data) {
//         //             if (data.Status === 400) {
//         //                 $('.mailing-list-subscribtion').empty();
//         //                 $('.mailing-list-subscribtion').html("Error: " + data.Message);
//         //             } else { // 200
//         //                 $('.mailing-list-subscribtion').empty();
//         //                 $('.mailing-list-subscribtion').html(data.Message);
//         //             }
//         //         });
//         // });
//         var callbackFnName = function(res) {
//         	document.getElementById('messageDiv').innerHTML = res.Message;
//         	console.log(res);
//         }
//
//         var targetForm = document.getElementById('subscription');
//         targetForm.addEventListener('submit', function(e) {
//         	e.preventDefault();
//         	var emailField = document.getElementById('fieldEmail');
//         	var keyValues = [];
//         	keyValues.add = function(k,v) {
//         		this.push([encodeURIComponent(k),encodeURIComponent(v)].join('='));
//         		return this;
//         	};
//         	var query = function(arr) {
//         		return '?' + arr.join('&');
//         	};
//         	keyValues.add('callback', 'callbackFnName').add(emailField.name, emailField.value);
//         	var url = this.action + query(keyValues);
//           console.log(url)
//         	var r = new XMLHttpRequest();
//         	r.open(this.method, url, true);
//         	r.onreadystatechange = function() {
//         		if(r.readyState === 4) {
//         			var res = document.createElement('script');
//         			res.type = 'text/javascript', res.async = 1, res.innerHTML = r.responseText;
//         			var s = document.getElementsByTagName('script')[0];
//         			s.parentNode.insertBefore(res,s);
//         		}
//         	}
//         	r.timeout = 3000;
//         	r.onerror = function() {console.log(this);}
//         	//r.send();
//         }, false);
//
//
//     }
// }

/* ----------------------------------------- Analytics tracking of buy button */

// Analytics tracking of buy button

function trackBuyButton() {
  if ((document.querySelector('.js-buy')) && (typeof ga == 'function')) {

    var links = document.querySelectorAll('.js-buy');

    links.forEach(function(link) {
      link.addEventListener('click', function (event) {
        var url = link.getAttribute('href');
        var title = link.getAttribute('title');
        ga('send', 'event', 'Buy', 'click', title);
      })
    })
  }
}




/*---------------------------------------------------------------------- Init */
document.addEventListener("DOMContentLoaded", function() {
  removeNoJs();
  mobileMenu();
  coverCarousel();
  bookCarousel();
  toggleLogo();
  trackBuyButton();
  //mailingListAjaxSubscription();
});

window.onresize = function() {
  toggleLogo();
}
